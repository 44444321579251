interface Props {
  style?: React.CSSProperties
}

export function Info({ style }: Props) {
  return (
    <i className="icon" style={style}>
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_518_3690)">
          <path d="M6.5 0C5.21442 0 3.95772 0.381218 2.8888 1.09545C1.81988 1.80968 0.986756 2.82484 0.494786 4.01256C0.00281636 5.20028 -0.125905 6.50721 0.124899 7.76809C0.375703 9.02896 0.994768 10.1872 1.90381 11.0962C2.81285 12.0052 3.97104 12.6243 5.23192 12.8751C6.49279 13.1259 7.79973 12.9972 8.98745 12.5052C10.1752 12.0132 11.1903 11.1801 11.9046 10.1112C12.6188 9.04229 13 7.78558 13 6.5C12.9981 4.77667 12.3127 3.12445 11.0941 1.90586C9.87556 0.687282 8.22334 0.00186392 6.5 0V0ZM6.5 11.9167C5.42869 11.9167 4.38143 11.599 3.49066 11.0038C2.5999 10.4086 1.90563 9.56264 1.49566 8.57287C1.08568 7.5831 0.978413 6.49399 1.18742 5.44326C1.39642 4.39253 1.91231 3.42737 2.66984 2.66984C3.42738 1.9123 4.39253 1.39642 5.44326 1.18741C6.49399 0.97841 7.58311 1.08568 8.57287 1.49565C9.56264 1.90563 10.4086 2.5999 11.0038 3.49066C11.599 4.38143 11.9167 5.42869 11.9167 6.5C11.9151 7.93611 11.3439 9.31294 10.3284 10.3284C9.31294 11.3439 7.93611 11.9151 6.5 11.9167Z" fill="#BCA36B"/>
          <path d="M6.49984 5.41675H5.95817C5.81451 5.41675 5.67674 5.47382 5.57515 5.5754C5.47357 5.67698 5.4165 5.81476 5.4165 5.95841C5.4165 6.10207 5.47357 6.23985 5.57515 6.34143C5.67674 6.44301 5.81451 6.50008 5.95817 6.50008H6.49984V9.75008C6.49984 9.89374 6.55691 10.0315 6.65849 10.1331C6.76007 10.2347 6.89785 10.2917 7.0415 10.2917C7.18516 10.2917 7.32294 10.2347 7.42452 10.1331C7.5261 10.0315 7.58317 9.89374 7.58317 9.75008V6.50008C7.58317 6.21276 7.46903 5.93721 7.26587 5.73405C7.06271 5.53088 6.78716 5.41675 6.49984 5.41675Z" fill="#BCA36B"/>
          <path d="M6.5 4.33325C6.94873 4.33325 7.3125 3.96948 7.3125 3.52075C7.3125 3.07202 6.94873 2.70825 6.5 2.70825C6.05127 2.70825 5.6875 3.07202 5.6875 3.52075C5.6875 3.96948 6.05127 4.33325 6.5 4.33325Z" fill="#BCA36B"/>
        </g>
        <defs>
          <clipPath id="clip0_518_3690">
            <rect width="13" height="13" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </i>
  )
}
