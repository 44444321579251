import { useState, useEffect } from 'react'
import { useWindowSize } from './WindowSize'

export const useIntersections = (elements: any[], options: any) => {
  const [intersection, setIntersection] = useState<boolean[]>([])

  const size = useWindowSize()

  useEffect(() => {
    const observers = elements.map((el, i) => {
      const observer = new IntersectionObserver(
        (entries) => {
          setIntersection((oldIntersection) => {
            const copy = JSON.parse(JSON.stringify(oldIntersection))
            copy[i] = entries[0].isIntersecting
            return copy
          })
        }, options
      )

      el.current && observer.observe(el.current)

      return observer
    })


    return () => {
      observers.forEach((observer, i) => {
        elements[i].current && observer.unobserve(elements[i].current)
      })
    }
  }, [size[0], size[1]]) // size change

  return intersection
}
