import styles from '@/styles/components/controls/Switcher.module.scss'
import { useEffect, useState } from 'react'

interface Props {
  className?:  string,
  options: string[],
  labels: string[],
  light?: boolean,
  active: string | number,
  onChange?(value: string): void
}

export const Switcher: React.FC<Props> = ({className = '', options, labels, active, onChange, light = false }) => {
  const [ activeValue, setActiveValue ] = useState(active)

  const isFirstOptionActive = () => {
    return options[0] === activeValue
  }

  const getLabel = (option: string, index: number) => {
    if (index !== undefined && labels) {
      return labels[index]
    }

    return capitalizeFirstLetter(option)
  }

  const capitalizeFirstLetter = (val: string) => {
    return val.charAt(0).toUpperCase() + val.slice(1)
  }

  const switchActive = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e.target.value)
    setActiveValue(e.target.value)
  }

  const isActive = (option: string) => {
    return option === activeValue
  }

  const indicatorStyle = () => {
    return {
      transform: isFirstOptionActive() ? 'translateX(0%)' : 'translateX(100%)'
    }
  }

  useEffect(() => {
    setActiveValue(active)
  }, [active])

  return (
    <div className={`${styles.switcher} ${ light && styles.switcher_light } ${className}`}>
      <span className={`${styles.switcher_indicator} switcher_indicator`} style={indicatorStyle()}></span>
      { options.map((option, i) => {
        return (
          <div key={i} className={`${styles.switcher_control} switcher_control`}>
            <input className={styles.switcher_input} type="radio" value={option} checked={isActive(option)} onChange={switchActive}/>
            <label className={`${styles.switcher_label} switcher_label ${isActive(option) ? `switcher_label_active ${styles.switcher_label_active}` : ''}`}>{getLabel(option, i)}</label>
          </div>
        )
      }) }
    </div>
  )
}
