import { Headline2 } from '@/components/common/Headline2'
import { Shield } from '@/components/icons/Shield'
import { useMediaQuery } from 'react-responsive'

export function ServiceRiskFreeComponent3() {
  const sm = useMediaQuery({minWidth: 600})

  return (
    <section className='pb-70-to-130'>
      <div className={`container container--sec container--max-w-1111 ${!sm ? 'bg--light-blue' : ''}`}>
        <div className='bg--light-blue'>
          <div className='md:px-[100px] py-[90px] px-[40px] mr-[-30px] md:mr-0 ml-[-30px] md:ml-0'>
            <Headline2 tag="h2" className="text-[23px] md:text-[25px] mb-40-to-50" color='black'>Try Our Service <span className='underline'>Risk Free <span className='inline-block ml-[5px] opacity-[0.4]'><Shield/></span></span></Headline2>
            <div className='flex flex-col md:flex-row color--black'>
              <div className='md:w-[50%] mr-[50px] md:mr-[20px] text-[17px]'>
                <p className='mb-[20px]'>We’re confident that our service gets results, and we want you to give it a try.</p>
                <p className='mb-[20px]'>But if it doesn’t work for you, you shouldn’t pay. That’s why we offer a simple guarantee:</p>
              </div>
              <div className='md:w-[50%] md:ml-[50px]'>
                <p className='font-bold italic text-[17px]'>If you don’t feel like this is working for you and producing amazing results, then email us within the first 30 days and we’ll promptly refund your payment in full (no hard feelings)!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
