interface Props {
  style?: React.CSSProperties
  className?: string
  fill?: string
}

export function Wing({ style, className = '', fill = '' }: Props) {
  return (
    <i className={`icon ${className}`} style={style}>
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.5235 8.74126C15.5235 13.028 12.0485 16.503 7.76176 16.503C3.47505 16.503 0 13.028 0 8.74126C0 4.45454 3.47505 0.979492 7.76176 0.979492C12.0485 0.979492 15.5235 4.45454 15.5235 8.74126ZM6.86397 12.851L12.6227 7.09232C12.8182 6.89677 12.8182 6.5797 12.6227 6.38415L11.9145 5.67599C11.719 5.48041 11.4019 5.48041 11.2063 5.67599L6.50987 10.3724L4.3172 8.17975C4.12165 7.9842 3.80458 7.9842 3.609 8.17975L2.90083 8.88792C2.70529 9.08346 2.70529 9.40054 2.90083 9.59608L6.15577 12.851C6.35135 13.0466 6.66839 13.0466 6.86397 12.851V12.851Z" fill={`${fill || 'white'}`}/>
      </svg>
    </i>
  )
}
