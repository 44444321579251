import styles from '@/styles/components/controls/Input.module.scss'
import { InputInvalid } from '@/components/icons/InputInvalid'
import { InputValid } from '@/components/icons/InputValid'
import cn from 'classnames'
import { forwardRef } from 'react'
import { Oval } from 'react-loader-spinner'

interface Props {
  label?: string,
  placeholder?: string,
  autocomplete?: string,
  error?: string,
  type?: 'text' | 'tel' | 'email',
  dark?: boolean,
  rounded?: boolean,
  height?: number,
  hideErrorText?: boolean,
  name?: string
  disabled?: boolean,
  valid?: boolean,
  invalid?: boolean,
  loading?: boolean,
  className?: string,
  value?: string,
  onChange?: (event: {
    target: any
    type?: any
  }) => Promise<void | boolean>
  onBlur?: (event: {
    target: any
    type?: any
  }) => Promise<void | boolean>
}

export const Input = forwardRef<HTMLInputElement, Props>(({
  label,
  placeholder = '',
  autocomplete = '',
  className = '',
  type = 'text',
  loading = false,
  error,
  dark = false,
  rounded = false,
  disabled = false,
  hideErrorText = false,
  height = 0,
  value = undefined,
  valid,
  name,
  onChange,
  onBlur
}, ref) => {
  const controlClasses = cn({
    [styles.control_input]: true,
  })

  const labelClasses = cn({
    [styles.control_input_label]: true,
    [styles.control_input_label_dark]: dark
  })

  const inputClasses = cn({
    [styles.control_input_input]: true,
    [styles.control_input_input_dark]: dark,
    [styles.control_input_rounded]: rounded,
    [styles.control_input_invalid]: !!error,
    [styles.control_input_valid]: valid
  })

  const inputStyles = {
    height: height ? height + 'px' : undefined
  }

  return (
    <div className={`${controlClasses} ${className}`}>
      {label && <label className={labelClasses}>{label}</label>}
      <div className={styles.control_input_wrapper}>
        <input type={type} style={inputStyles} placeholder={placeholder} autoComplete={autocomplete || undefined} className={inputClasses} disabled={disabled} onChange={onChange} value={value} onBlur={onBlur} name={name} ref={ref} />
        {valid && <InputValid className={styles.control_input_icon} />}
        {!!error && <InputInvalid className={styles.control_input_icon}/>}
        {loading &&
          <span className={styles.control_input_loader}>
            <Oval
              ariaLabel="loading-indicator"
              height={20}
              width={20}
              strokeWidth={5}
              strokeWidthSecondary={2}
              color="white"
              secondaryColor="transparent"
            />
          </span>
        }
      </div>
      {error && !hideErrorText && <span className={styles.control_input_error}>{error}</span>}
    </div>
  )
})

Input.displayName = 'Input'
