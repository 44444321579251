import Hero from '@/components/sections/Hero'
import { Headline2 } from '@/components/common/Headline2'
import { CustomTrustpilot } from '@/components/common/CustomTrustpilot'
import { Button } from '@/components/controls/Button'
import { useQuery } from '@/utils/url'
import { PlanetDecor } from '@/components/decors/PlanetDecor'

import testim_ava1 from '@/public/images/testim_ava1.jpg'
import testim_ava2 from '@/public/images/testim_ava2.jpg'
import testim_ava3 from '@/public/images/XayLi_Barclay.jpeg'
import testim_ava4 from '@/public/images/testim_ava12.jpg'

import { TestimonialBig } from '@/components/common/TestimonialBig'
import { useEffect, useState } from 'react'

import { useBetterMediaQuery } from '@/hooks/BetterMediaQuery'
import { SimpleCarousel, SimpleCarouselItem } from '@/components/carousel/SimpleCarousel'
import { emitter } from '@/utils/emitter'
import Modal from 'react-modal'
import { CrossBtn } from '@/components/controls/CrossBtn'
import { Carousel3d } from '@/components/carousel-3d/Carousel3d'
import dynamic from 'next/dynamic'
import { EndlessCarousel } from '@/components/carousel/EndlessCarousel'
import Trends from '@/components/icons/Trends'
import Entrepreneur from '@/components/icons/Entrepreneur'
import Inc from '@/components/icons/Inc'
import FastCompany from '@/components/icons/FastCompany'
import { Wing } from '@/components/icons/Wing'
import { useElementWidth } from '@/hooks/ElementWidth'
import queryString from 'query-string'
import { Info } from '@/components/icons/Info'

const DynamicHardScienceOffcanvas = dynamic( // @ts-ignore
  () => import('@/components/offcanvas/HardScienceOffcanvas').then((mod) => mod.HardScienceOffcanvas),
  { ssr: false }
)

export function HeroHomeVariant5() {
  const sm = useBetterMediaQuery('(min-width: 768px)')
  const lg = useBetterMediaQuery('(min-width: 1024px)')
  const xl = useBetterMediaQuery('(min-width: 1280px)')
  const checkoutLink = useQuery('/checkout')
  const [show, setShow] = useState(false)
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [showOffcanvas, setShowOffcanvas] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const heroWidth = useElementWidth('hero-container', 100)

  const showHandler = (e: any) => {
    e.stopPropagation()
    setLoaded(true)
    setShowOffcanvas(true)

    emitter.emit('hard-science-offcanvas-opened')
  }

  const closeHandler = () => {
    setShowOffcanvas(false)

    emitter.emit('hard-science-offcanvas-closed')
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleCloseVideoModal = () => {
    setShowVideoModal(false)
  }

  const handleOpenVideoModal = () => {
    setShowVideoModal(true)
  }

  const handleOpen = () => {
    emitter.emit('guarantee-info-opened')
    setShow(true)
  }

  const applyWidth = () => {
    if (!sm) {
      return heroWidth || 350
    }

    if (sm && !lg) {
      return 280
    }

    if (sm) {
      return 320
    }
  }

  useEffect(() => {
    const query = queryString.parse(window.location.search)
    const script = document.getElementById('wistia_script')

    if (!script) {
      const wistiaScript = document.createElement('script')
      wistiaScript.id = 'wistia_script'
      wistiaScript.type = 'text/javascript'
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js'
      wistiaScript.async = true
      document.body.appendChild(wistiaScript)
    }

    if (query['explainer-video'] !== undefined) {
      handleOpenVideoModal()
    }
  }, [])

  return (
    <Hero screenHeight={false}>
      <div className="flex container flex-col md:flex-row justify-between items-center container--sec container--max-w-1111 larger:relative mt-0">
        <div className='w-full md:w-[53%] md:mt-[40px]'>
          <Headline2 tag="h1" weight={800} className="text-[28px] xl:text-[34px] 2xl:text-[36px] !leading-[1.3] 3xl:text-[40px] mt-[16px] lg:mt-[40px] mb-[30px] md:mb-[50px] max-w-[560px] xxl:max-w-[860px] z-10 relative">The Coached Planning Ritual That Doubles Your Productivity In 30 Minutes A Week</Headline2>
          <div id="hero-container" className="text-18-to-20 justify-between w-full md:max-w-[420px] xxl:max-w-[500px]">
            <p className='mb-[12px] md:mb-[24px]'>This methodology helps our clients enjoy:</p>
            <span className="list list--white mt-[5px]">explosive revenue growth.</span>
            <span className="list list--white mt-[5px]">frictionless delegation.</span>
            <span className="list list--white mt-[5px]">optimized personal habits.</span>
            <span className="list list--white mt-[5px]">deep-work every day.</span>
            <span className="list list--white mt-[5px]">turning big ideas into results, fast.</span>
            <p className='mt-[14px] md:mt-[24px]'>Thousands of entrepreneurs have embedded our planning system and accountability coaching permanently on their teams and hit their biggest goals.</p>
            <p className='mt-[24px] md:mt-[48px] font-bold'>Now it’s your turn.</p>
          </div>
        </div>
        <div className='relative hidden md:block w-full md:w-[48%] flex flex-col items-center justify-center'>
          <div className='flex h-[15px] scale-[0.8] mb-[25px] justify-center'>
            <CustomTrustpilot stars={true} large={true} numberOfReviews='206'></CustomTrustpilot>
          </div>
          {sm && <Carousel3d
            count={4}
            width={330}
            height={260}
            inverseScaling={300}
            display={3}
            perspective={10}
            bullets={true}
            divisor={sm && !lg ? 2.8 : 1.8}
            startIndex={0}
            animationSpeed={600}
            controlsVisible={true}
            className="carousel-3d-container--sec"
            nextHtml={<span className='carousel_3d_next_control--sec'></span>}
            prevHtml={<span className='carousel_3d_prev_control--sec'></span>}
            slides={[
              {
                content: <TestimonialBig
                  name="Skye K"
                  className="testimonial-hero-anim-less"
                  text="In the 3 months I’ve been with Commit Action my revenue has increased by 50% and I’ve hired a team to help me execute which has bought my total hours worked down to 25 per week (while growing 2 companies)"
                  avatarUrl={testim_ava1.src}
                  caption="Founder | Profit Partnerships Group"
                  height={255}
                ></TestimonialBig>
              },
              {
                content: <TestimonialBig
                  name="Steve Krakower"
                  className="testimonial-hero-anim-less"
                  text="The results speak for themselves — in the few months that I have been working working with Commit Action, our revenue has increased 25% and we finally hit our first 6-figure month"
                  avatarUrl={testim_ava2.src}
                  caption="Founder | Harbor Marketing Agency"
                  height={255}
                ></TestimonialBig>
              },
              {
                content: <TestimonialBig
                  name="XayLi Barclay"
                  className="testimonial-hero-anim-less"
                  text="Since signing up for Commit Action, I've been able to blow through my initial goals and create even bigger ones. Meeting with my coach Nick is always one of the highlights of my week!"
                  avatarUrl={testim_ava3.src}
                  caption="Founder &amp; CEO | TDG Visual Solutions"
                  height={255}
                ></TestimonialBig>
              },
              {
                content: <TestimonialBig
                  name="Khierstyn Ross"
                  className="testimonial-hero-anim-less"
                  text="As an entrepreneur, I constantly feel overwhelmed and second guess if what I'm working on will move me toward my goals, with Commit Action's focus, now I don't feel that way as I'm working"
                  avatarUrl={testim_ava4.src}
                  caption="Founder &amp; CEO | Launch &amp; Scale™"
                  height={255}
                ></TestimonialBig>
              }
            ]}
          />}
        </div>
      </div>
      <div className="container container--sec container--max-w-1111 larger:relative">
        <div className='pb-70-to-130 flex flex-col md:flex-row justify-between items-center'>
          <div className='w-full md:w-[52%]'>
            <div className='flex flex-col lg:flex-row mb-[40px]'>
              <div className='w-full md:max-w-[380px] mt-[30px] lg:mt-[60px] order-2 lg:order-1'>
                <Button href={checkoutLink} boldFont={true} type="primary" noWrap={true} textCenter={true} fullWidth={true} disableArrow={true} maxWidth={380}><Wing className='mr-[15px]'/>Sign Up &amp; Pay Now</Button>
                <p className='text-center mt-[10px] font--primary text-[17px] flex items-center justify-center cursor-pointer' onClick={handleOpen}><em className='mr-[4px]'>No Contract. 30-Day Guarantee</em><Info/></p>
              </div>
            </div>
            {!sm && <div>
              <div className='flex h-[15px] scale-[0.8] mt-[60px] mb-[25px] justify-center'>
                <CustomTrustpilot stars={true} large={true} numberOfReviews='206'></CustomTrustpilot>
              </div>
              <SimpleCarousel
                loop={true}
                width={applyWidth()}
                autoplay={true}
                animationSpeed={10000}
                gap={16}
                controlsVisible={sm ? true : false}
                endLess={true}
                bullets={true}
                overflow={'visible'}
                containerClass="checkout-testimonials-carousel2"
                nextHtml={<span className='checkout-testimonials-carousel_next_control home-page-carousel_next_control'></span>}
                prevHtml={<span className='checkout-testimonials-carousel_prev_control home-page-carousel_prev_control'></span>}
              >
                <SimpleCarouselItem>
                  <TestimonialBig
                    name="Skye K"
                    className="testimonial-hero-anim-less"
                    text="“In the 3 months I’ve been with Commit Action my revenue has increased by 50% and I’ve hired a team to help me execute which has bought my total hours worked down to 25 per week (while growing 2 companies)”"
                    avatarUrl={testim_ava1.src}
                    caption="Founder | Profit Partnerships Group"
                    height={sm ? 280 : 255}
                  ></TestimonialBig>
                </SimpleCarouselItem>
                <SimpleCarouselItem>
                  <TestimonialBig
                    name="Steve Krakower"
                    className="testimonial-hero-anim-less"
                    text="“The results speak for themselves — in the few months that I have been working working with Commit Action, our revenue has increased 25% and we finally hit our first 6-figure month”"
                    avatarUrl={testim_ava2.src}
                    caption="Founder &amp; CEO | Harbor Marketing Agency"
                    height={sm ? 280 : 255}
                  ></TestimonialBig>
                </SimpleCarouselItem>
                <SimpleCarouselItem>
                  <TestimonialBig
                    name="XayLi Barclay"
                    className="testimonial-hero-anim-less"
                    text="“Since signing up for Commit Action, I've been able to blow through my initial goals and create even bigger ones. Meeting with my coach Nick is always one of the highlights of my week!”"
                    avatarUrl={testim_ava3.src}
                    caption="Founder &amp; CEO | TDG Visual Solutions"
                    height={sm ? 280 : 255}
                  ></TestimonialBig>
                </SimpleCarouselItem>
                <SimpleCarouselItem>
                  <TestimonialBig
                    name="Khierstyn Ross"
                    className="testimonial-hero-anim-less"
                    text="“As an entrepreneur, I constantly feel overwhelmed and second guess if what I'm working on will move me toward my goals, with Commit Action's focus, now I don't feel that way as I'm working”"
                    avatarUrl={testim_ava4.src}
                    caption="Founder &amp; CEO | Launch &amp; Scale™"
                    height={sm ? 280 : 255}
                  ></TestimonialBig>
                </SimpleCarouselItem>
              </SimpleCarousel>
            </div>}
          </div>
          <div className='relative w-full md:w-[48%]'>
            <div className='mt-[16px] md:mt-[40px] text-center md:text-left'>
              <span className='font--accent text-[12px] font-bold'>Recommended in:</span>
              <EndlessCarousel
                delayStart={600}
                className="logos-carousel"
                slides={[
                  {
                    content: <div className="mr-[30px] w-[120px] h-[60px]"><Trends style={{height: '60px'}} responsive={true}/></div>
                  },
                  {
                    content: <div className="mr-[30px] mt-[5px] w-[150px] h-[60px]"><Entrepreneur style={{height: '60px'}} responsive={true}/></div>
                  },
                  {
                    content: <div className="mr-[30px] w-[80px] h-[60px]"><Inc style={{height: '60px'}} responsive={true}/></div>
                  },
                  {
                    content: <div className="mr-[30px] mt-[2px] w-[155px] h-[60px]"><FastCompany style={{height: '60px'}} responsive={true}/></div>
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-[auto] top-0 lg:top-auto rotate-180 lg:rotate-0 lg:bottom-[0] h-[600px] larger:h-[800px] left-0 w-full overflow-hidden pointer-events-none">
        <PlanetDecor className="h-[900px] larger:h-[1200px]"/>
      </div>
      <Modal isOpen={show} className='modal-content--white' ariaHideApp={false}>
        <CrossBtn className="modal__close-btn modal__close-btn-sec opacity-20" color="black" onClick={() => {
          handleClose()
        }}/>
        <div className='color--black mt-[20px]'>
          <Headline2 tag="h1" className="text-[22px] mb-[20px]">Try Our Service Risk Free</Headline2>
          <p className='mb-[20px]'>We’re confident that our service gets results, and we want you to give it a try.</p>
          <p className='mb-[20px]'>But if it doesn’t work for you, you shouldn’t pay. That’s why we offer a simple guarantee:</p>
          <p className='font-bold italic'>If you don’t feel like this is working for you and producing amazing results, then email us within the first 30 days and we’ll promptly refund your payment in full (no hard feelings)!</p>
        </div>
      </Modal>
      {loaded && <DynamicHardScienceOffcanvas show={showOffcanvas} close={closeHandler} />}
      <Modal isOpen={showVideoModal} className='modal-content--white' ariaHideApp={false}>
        <CrossBtn className="modal__close-btn modal__close-btn-sec modal__close-btn-tiny" color="black" onClick={() => {
          handleCloseVideoModal()
        }}/>
        <div>
          <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <div
              className="wistia_responsive_wrapper"
              style={{ height: '100%', left: '0', position: 'absolute', top: '0', width: '100%'}}
            >
              <div className={'wistia_embed wistia_async_9e5ami0wj2 seo=false videoFoam=true'} style={{ height: '100%', position: 'relative', width: '100%' }}
              ></div>
            </div>
          </div>
        </div>
      </Modal>
    </Hero>
  )
}
