import styles from '@/styles/components/decors/PlanetDecor.module.scss'
import { useRef, useLayoutEffect } from 'react'
import { useWindowSize } from '@/hooks/WindowSize'

interface Props {
  style?: React.CSSProperties,
  className?: string
}

export const PlanetDecor: React.FC<Props> = ({style, className = ''}) => {
  const canvas = useRef<HTMLCanvasElement>(null)
  const wrapper = useRef<HTMLDivElement>(null)
  const size = useWindowSize() // To trigger rerender on resize

  useLayoutEffect(() => {
    const dpr = window.devicePixelRatio || 1

    if (canvas.current && wrapper.current) {
      const ctx = canvas.current.getContext('2d')
      const dims = wrapper.current.getBoundingClientRect()

      canvas.current.width = Math.round(dpr * dims.right) - Math.round(dpr * dims.left)
      canvas.current.height = Math.round(dpr * dims.bottom) - Math.round(dpr * dims.top)
      canvas.current.style.opacity = '0.2'

      if (ctx) {
        ctx.scale(dpr, dpr)
        const gradient = ctx.createRadialGradient(dims.width / 2, dims.height, dims.height / 10, dims.width / 2, dims.height * 3, dims.height * 3)

        gradient.addColorStop(0, '#be55f0')
        gradient.addColorStop(0.4, '#2e6ef3')
        gradient.addColorStop(0.7, '#5ad082')
        gradient.addColorStop(1, 'transparent')

        ctx.fillStyle = gradient
        ctx.fillRect(0, 0, dims.width, dims.height)
      }
    }
  }, [size])

  return (
    <div className={`${styles.planet_decor_wrapper} ${className}`} ref={wrapper} style={style}>
      <canvas ref={canvas}></canvas>
    </div>
  )
}
