import styles from '@/styles/components/decors/StepSec.module.scss'

interface Props {
  style?: React.CSSProperties,
  className?: string,
  number: number,
  total: number
}

export const StepSec: React.FC<Props> = ({ style, className = '', number = 1, total = 1 }) => {
  return (
    <div className={`${className} ${styles.step_sec}`} style={style}>
      <span className={styles.step_sec_text}>Step 0{number}</span>
      <div className={styles.step_sec_progress_bar}>
        <span style={{width: number / total * 100}} className={styles.step_sec_active_progress_bar}></span>
      </div>
    </div>
  )
}
