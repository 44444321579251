import styles from '@/styles/components/sections/Hero.module.scss'
import cn from 'classnames'

interface Props {
  screenHeight?: boolean,
  className?: string,
  secondary?: boolean
}

const Hero: React.FC<Props> = ({ screenHeight = false, children, className = '', secondary = false }) => {
  const classes = cn({
    [styles.hero]: true,
    [styles.hero_screen_height]: screenHeight,
    [className]: className,
    [styles.hero_sec]: secondary
  })

  return (
    <section className={classes}>
      {children}
    </section>
  )
}

export default Hero
