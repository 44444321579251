import { Switcher } from '@/components/controls/Switcher'
import { Crown } from '../icons/Crown'
import { Headline2 } from '@/components/common/Headline2'
import { setStepTwoCheckout } from '@/store/actions/checkout'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store/reducers'
import Link from 'next/link'
import { useQuery } from '@/utils/url'

type Props = {
  prices: any,
  light?: boolean
}

export function PlansElite({ prices, light = false }: Props) {
  const dispatch = useDispatch()
  const checkoutState = useSelector((state: RootState) => state.checkout)
  const [payStrategy, setPayStrategy] = useState('annual')
  const eliteLink = useQuery('/elite')

  const activeElitePlan = () => {
    if (payStrategy == 'annual' && checkoutState.plan === 'eliteAnnual') {
      return 'active-scale-border'
    } else if (payStrategy == 'monthly' && checkoutState.plan === 'eliteMonthly') {
      return 'active-scale-border'
    }
    return ''
  }

  const activeStandardPlan = () => {
    if (payStrategy == 'annual' && checkoutState.plan === 'annual') {
      return 'active-scale-border'
    } else if (payStrategy == 'monthly' && checkoutState.plan === 'monthly') {
      return 'active-scale-border'
    }
    return ''
  }

  useEffect(() => {
    if (checkoutState.plan === 'eliteAnnual' || checkoutState.plan === 'annual') {
      setPayStrategy('annual')
    } else if (checkoutState.plan === 'eliteMonthly' || checkoutState.plan === 'monthly') {
      setPayStrategy('monthly')
    }
  })

  return (
    <div>
      <div className='max-w-[312px] mx-auto'>
        <div className='text-right'>
          <span className='mr-[50px] color--green font--accent text-[13px]'>*Save 20%</span>
        </div>
        <Switcher className='elite-switcher elite-switcher-white' options={['monthly', 'annual']} labels={['Pay monthly', 'Pay annual']} active={payStrategy} onChange={(value) => {
          setPayStrategy(value)
          dispatch(setStepTwoCheckout(''))
        }}></Switcher>
      </div>
      <div className='flex flex-col md:flex-row mt-[56px]'>
        <div className={`${light ? 'bg-white shadow-2xl' : 'bg-[#212D3A]'}  mb-[50px] md:mb-0 md:mr-[18px] p-[30px] lg:p-[50px] w-full rounded-[10px] relative cursor-pointer hover-scale-border ${activeElitePlan()}`} onClick={() => {
          if (payStrategy === 'annual') {
            dispatch(setStepTwoCheckout('eliteAnnual'))
          } else {
            dispatch(setStepTwoCheckout('eliteMonthly'))
          }
        }}>
          <Crown className='absolute top-[-12px] left-0 right-0 m-auto w-[29px]'/>
          {payStrategy === 'annual' &&
            <>
              <Headline2 tag="h3" className="text-[20px] lg:text-[26px] mb-[10px] lg:mb-[20px] text-center" color={light ? 'black' : 'white'}  weight={500}>Elite Membership</Headline2>
              <p className='text-[14px] italic text-center mb-[40px] h-[66px] lg:h-[86px]'><span className='font--accent text-[28px] not-italic color-secondary'>${ Math.ceil(prices.eliteAnnual / 12) } <span className='text-[13px]'>/ per month</span></span><p className='color--green font--accent text-[12px] not-italic font-semibold mb-[5px] text-shadow--green'>Save $5,520 now!</p><p className='opacity-[0.5] font-extralight'>Billed annually</p></p>
            </>
          }
          {payStrategy === 'monthly' &&
            <>
              <Headline2 tag="h3" className="text-[20px] lg:text-[26px] mb-[10px] lg:mb-[20px] text-center" color={light ? 'black' : 'white'}  weight={500}>Elite Membership</Headline2>
              <p className='text-[14px] italic text-center mb-[40px] h-[66px] lg:h-[86px]'><span className='font--accent text-[28px] not-italic color-secondary'>${ prices.eliteMonthly } <span className='text-[13px]'>/ per month</span></span><p className='opacity-[0.5] font-extralight'>Billed monthly</p></p>
            </>
          }
          <Headline2 tag="h4" className="text-[14px] mb-[15px] lg:mb-[30px] ml-[25px]" color={light ? 'black' : 'white'} weight={400}>All CA Membership features, plus:</Headline2>
          <ul className='text-[13px] lg:text-[17px]'>
            <li className='pl-[26px] relative mb-[10px] lg:mb-[20px]'><span className='list-checkmark !ml-[-8px]'></span><span className='color-secondary'>100% more coaching time</span> tailored fluidly across your unique work week</li>
            <li className='pl-[26px] relative mb-[10px] lg:mb-[20px]'><span className='list-checkmark !ml-[-8px]'></span><span className='color-secondary'>Quarterly planning deep dive</span> (extra session)</li>
            <li className='pl-[26px] relative'><span className='list-checkmark !ml-[-8px]'></span><span className='color-secondary'>Embed your coach</span> into your systems: Add your coach to your personal software stack (Google suite, Asana, Monday etc)</li>
          </ul>
          <div className=' pl-[26px] flex mt-[20px]'>
            <Link href={eliteLink} passHref><a className='link'>Learn more about Elite Membership</a></Link>
          </div>
        </div>
        <div className={`${light ? 'bg-white shadow-2xl' : 'bg-[#212D3A]'} md:ml-[18px] p-[30px] lg:p-[50px] w-full rounded-[10px] relative cursor-pointer hover-scale-border ${activeStandardPlan()}`} onClick={() => {
          if (payStrategy === 'annual') {
            dispatch(setStepTwoCheckout('annual'))
          } else {
            dispatch(setStepTwoCheckout('monthly'))
          }
        }}>
          <span className='text-[13px] most-popular-label font--accent'>Most Popular</span>
          {payStrategy === 'annual' &&
            <>
              <Headline2 tag="h3" className="text-[20px] lg:text-[26px] mb-[10px] lg:mb-[20px] text-center" color={light ? 'black' : 'white'}  weight={500}>Commit Action Membership</Headline2>
              <p className='text-[14px] italic text-center mb-[40px] h-[66px] lg:h-[86px]'><span className='font--accent text-[28px] not-italic color-secondary'>${Math.ceil(prices.annual / 12)} <span className='text-[13px]'>/ per month</span></span><p className='color--green font--accent text-[12px] not-italic font-semibold mb-[5px] text-shadow--green'>Save $1,380 now!</p><p className='opacity-[0.5] font-extralight'>Billed annually</p></p>
            </>
          }
          {payStrategy === 'monthly' &&
            <>
              <Headline2 tag="h3" className="text-[20px] lg:text-[26px] mb-[10px] lg:mb-[20px] text-center" color={light ? 'black' : 'white'}  weight={500}>Commit Action Membership</Headline2>
              <p className='text-[14px] italic text-center mb-[40px] h-[66px] lg:h-[86px]'><span className='font--accent text-[28px] not-italic color-secondary'>${prices.monthly} <span className='text-[13px]'>/ per month</span></span><p className='opacity-[0.5] font-extralight'>Billed monthly</p></p>
            </>
          }
          <ul className='text-[13px] lg:text-[15px]'>
            <li className='pl-[26px] relative mb-[10px] lg:mb-[20px]'><span className='list-checkmark !ml-[-8px]'></span>Dedicated coach, highly trained and US based.</li>
            <li className='pl-[26px] relative mb-[10px] lg:mb-[20px]'><span className='list-checkmark !ml-[-8px]'></span>Weekly 1-to-1 coaching calls (4 per month)</li>
            <li className='pl-[26px] relative mb-[10px] lg:mb-[20px]'><span className='list-checkmark !ml-[-8px]'></span>60 min Strategic Onboarding Call</li>
            <li className='pl-[26px] relative mb-[10px] lg:mb-[20px]'><span className='list-checkmark !ml-[-8px]'></span>Easy-to-use planning and productivity app (managed with your coach)</li>
            <li className='pl-[26px] relative mb-[10px] lg:mb-[20px]'><span className='list-checkmark !ml-[-8px]'></span>Custom action plan each week, auto-synced to your preferred calendar.</li>
            <li className='pl-[26px] relative mb-[10px] lg:mb-[20px]'><span className='list-checkmark !ml-[-8px]'></span>Regular support and checkins with text and email.</li>
            <li className='pl-[26px] relative mb-[10px] lg:mb-[20px]'><span className='list-checkmark !ml-[-8px]'></span>Attend monthly live webinars, quarterly workshops, and annual events.</li>
            <li className='pl-[26px] relative'><span className='list-checkmark !ml-[-8px]'></span>Access our full content library of video trainings and webinars.</li>
          </ul>
        </div>
      </div>
    </div>
  )
}
