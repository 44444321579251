import styles from '@/styles/components/common/Panel.module.scss'

interface Props {
  className?: string
}

export const Panel: React.FC<Props> = ({ className, children }) => {
  return (
    <div className={`${styles.panel} ${className}`}>
      {children}
    </div>
  )
}
