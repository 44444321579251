import { useState, useLayoutEffect } from 'react'

export function useElementWidth(elementId: string, delay = 200, rerenderTrigger?: any) {
  const [width, setWidth] = useState(0)

  useLayoutEffect(() => {
    function updateWidth() {
      const width = document.getElementById(elementId)?.offsetWidth

      if (width) {
        setWidth(width)
      }
    }

    window.addEventListener('resize', updateWidth)

    setTimeout(() => {
      updateWidth()
    }, delay) // set timeout so correct width will be returned, because css will be applied

    return () => window.removeEventListener('resize', updateWidth)
  }, [rerenderTrigger])

  return width
}
