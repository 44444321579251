import dynamic from 'next/dynamic'
import { Headline2 } from '@/components/common/Headline2'
import { LottieRefCurrentProps } from 'lottie-react'
import { MutableRefObject, useEffect, useRef, useState, createRef, forwardRef } from 'react'
import { useIntersections } from '@/hooks/Intersections'
import { emitter } from '@/utils/emitter'
import { usePinable } from '@/hooks/Pinable'
import { StepSec } from '@/components/decors/StepSec'

const numberOfRows = 4

const DynamicHardScienceOffcanvas = dynamic( // @ts-ignore
  () => import('@/components/offcanvas/HardScienceOffcanvas').then((mod) => mod.HardScienceOffcanvas),
  { ssr: false }
)

const DynamicAnimation = dynamic( // @ts-ignore
  () => import('@/components/dynamic/DynamicAnimation').then((mod) => mod.DynamicAnimation),
  { ssr: false }
)

type Props = {
  name: string
}

const DynamicAnimationWithRef = forwardRef((props: Props, ref) => (
  // @ts-ignore
  <DynamicAnimation {...props} forwardedRef={ref} />
))

DynamicAnimationWithRef.displayName = 'DynamicAnimationWithRef'

export const HowItWorksSection3: React.FC = () => {
  const lottieRefs = useRef<MutableRefObject<LottieRefCurrentProps | null>[]>([])
  const rowRefs = useRef<MutableRefObject<HTMLDivElement | null>[]>([])
  const [animsLoaded, setAnimsLoaded] = useState(false)
  const [animsToLoad, setAnimsToLoad] = useState([0, 0, 0, 0])
  const intersections = useIntersections(rowRefs.current, {
    threshold: 0.5
  })

  const isOffsetReached = usePinable(5)

  const [show, setShow] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const closeHandler = () => {
    setShow(false)

    emitter.emit('hard-science-offcanvas-closed')
  }

  const showHandler = (e: any) => {
    e.stopPropagation()
    setLoaded(true)
    setShow(true)

    emitter.emit('hard-science-offcanvas-opened')
  }

  const buildRefs = () => {
    for (let i = 0; i < numberOfRows; i++) {
      lottieRefs.current[i] = lottieRefs.current[i] || createRef()
      rowRefs.current[i] = rowRefs.current[i] || createRef()
    }
  }

  useEffect(() => {
    buildRefs()

    return () => {
      for (let i = 0; i < numberOfRows; i++) {
        lottieRefs.current[i].current?.destroy()
        rowRefs.current[i].current = null
      }
    }
  }, [])

  useEffect(() => {
    // For a performance reasons we need to run animations components with delay
    if (isOffsetReached && !animsLoaded) {
      setAnimsToLoad([1, 0, 0, 0])

      setTimeout(() => {
        setAnimsToLoad([1, 1, 0, 0])
      }, 600)

      setTimeout(() => {
        setAnimsToLoad([1, 1, 1, 0])
      }, 1500)

      setTimeout(() => {
        setAnimsToLoad([1, 1, 1, 1])
        setAnimsLoaded(true)
      }, 2000)
    }
  }, [isOffsetReached])

  useEffect(() => {
    if (!intersections.length) return

    intersections.forEach((intersection, i) => {
      if (intersection) {
        lottieRefs.current[i].current?.play()
      } else {
        lottieRefs.current[i].current?.pause()
      }
    })
  })

  return (
    <section id="how-it-works" className='bg--dark pt-70-to-130'>
      <div className="container container--sec container--max-w-1111">
        <Headline2 tag="h2" className="text-center text-24-to-38 mb-[40px] lg:mb-[80px]">How It Works</Headline2>
        <div
          className="flex flex-col md:flex-row justify-between items-center pb-70-to-130"
        >
          <div className="md:w-[45%] shrink-0 pb-[70px] md:pb-0">
            <StepSec number={1} total={3} className="mb-[30px]"/>
            <Headline2 tag="h3" className="text-18-to-22 mb-30-to-40 font-semibold" weight={800}>We Help You Craft Fine-Tuned Weekly<br/> Action Plans</Headline2>
            <p className="text-18-to-20 mb-[30px]">When you sign up for Commit Action, you’ll immediately be assigned a dedicated accountability coach.</p>
            <p className="text-18-to-20">Then once a week, at a time you commit to, you’ll get on the phone with your coach to create your weekly action plan.</p>
            <Headline2 tag="h4" className="text-[18px] mb-[20px] mt-[50px]" weight={200}>Your Coach Will Do All The Heavy Lifting</Headline2>
            <span className="list mt-[5px]">asking the right questions</span>
            <span className="list mt-[5px]">organizing your thoughts</span>
            <span className="list mt-[5px]">taking detailed notes on each session</span>
            <span className="list mt-[5px]">breaking down goals into realistic action steps</span>
            <span className="list mt-[5px]">scheduling time on your calendar</span>
            <span className="list mt-[5px]">tracking results and progress</span>
            <p className="text-18-to-20 mt-[30px]">…so you effectively clarify your goals, identify your highest impact activities, and create a focused action plan for the week ahead.</p>
            <p className="text-18-to-20 mt-[30px] font-thin"><b>You finish the call with your week’s game plan set, scheduled, and mapped out in our app, which then auto-syncs directly to your preferred calendar.</b> (Your coach also emails & texts you a personalized summary for reference.)</p>
          </div>
          <div className="md:w-[45%]" ref={rowRefs.current[2]}>
            { animsToLoad[2] && <DynamicAnimationWithRef ref={lottieRefs.current[2]} name="how-it-works-3" /> }
          </div>
        </div>
        <div
          className="flex flex-col md:flex-row justify-between items-center pb-70-to-130"
        >
          <div className="md:w-[45%] shrink-0 pb-[70px] md:pb-0 md:order-1">
            <StepSec number={2} total={3} className="mb-[30px]"/>
            <Headline2 tag="h3" className="text-18-to-22 mb-30-to-40 font-semibold" weight={800}>We Keep You Accountable & Taking Action</Headline2>
            <p className="text-18-to-20 mb-[30px]">Armed with your plan, you go into your week with incredible clarity and focus. You no longer feel overwhelmed.</p>
            <p className="text-18-to-20">You wake up in the morning and operate with deliberate intention because you already know exactly what you need to do for the day.</p>
            <Headline2 tag="h4" className="text-[18px] mb-[20px] mt-[50px] !leading-[1.5]" weight={200}>Throughout the week, your accountability coach checks in with you via email or SMS to make sure you’re on track.</Headline2>
            <p className="text-18-to-20 mb-[30px]">You execute and you feel good because you know you’re doing the highest impact activities — things you know you should be doing and are finally getting to.</p>
            <p className="text-18-to-20 mb-[30px]">Moving in circles is a thing of the past. Instead you finally make measurable, weekly progress towards your goals with confidence and momentum.</p>
            <p className="text-18-to-20 mb-[30px]">Taking actions requires less grind, resistance, and internal motivation because you naturally feel the external accountability pulling you forward.</p>
            <p className="text-18-to-20">Finally, you’re not going at it alone anymore.</p>
          </div>
          <div className="md:w-[40%]" ref={rowRefs.current[3]}>
            { animsToLoad[3] && <DynamicAnimationWithRef ref={lottieRefs.current[3]} name="how-it-works-4" /> }
          </div>
        </div>
        <div
          className="flex flex-col md:flex-row justify-between items-center pb-70-to-130"
          ref={rowRefs.current[1]}
        >
          <div className="md:w-[40%] shrink-0 pb-[70px] md:pb-0">
            <StepSec number={3} total={3} className="mb-[30px]"/>
            <Headline2 tag="h3" className="text-18-to-22 mb-30-to-40 font-semibold" weight={800}>We Help You Review Each Week & Improve</Headline2>
            <p className="text-18-to-20 mb-[30px]">At the end of the week and the start of the next, your ritual starts again when your coach calls you.</p>
            <p className="text-18-to-20 mb-[30px]">They’ll start by analyzing your past 7 days: Successes. Struggles. What got done. What didn’t. What you could do better next time.</p>
            <p className="text-18-to-20 mb-[30px]">Together, you’ll celebrate the wins and draw powerful insights from any obstacles you faced.</p>
            <p className="text-18-to-20 mb-[30px]">Your coach will record everything down in our app to support potent self reflection and make consistent improvements over time.</p>
            <p className="text-18-to-20">Finally, while integrating lessons from the past week, your coach will re-adjust what’s necessary and begin guiding the ritual for the new week ahead…</p>
          </div>
          <div className="md:w-[45%]">
            { animsToLoad[1] && <DynamicAnimationWithRef ref={lottieRefs.current[1]} name="how-it-works-2" /> }
          </div>
        </div>
      </div>
    </section>
  )
}
