interface Props {
  style?: React.CSSProperties,
  className?: string,
  width?: number,
  height?: number
}

export function Crown({ style, className = '', width = 0, height = 0 }: Props) {
  return (
    <i className={`${className} icon`} style={style}>
      <svg width={width || 29} height={height || 22} viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.5089 2.41681C27.0674 2.24583 26.5811 2.20089 26.112 2.28771C25.6429 2.37452 25.2122 2.58917 24.8748 2.90431L21.75 5.8091L16.2086 0.65776C15.7554 0.236597 15.1408 0 14.5 0C13.8592 0 13.2446 0.236597 12.7914 0.65776L7.25 5.8091L4.12525 2.90431C3.78728 2.59022 3.35671 2.37634 2.88799 2.28969C2.41927 2.20305 1.93344 2.24754 1.49192 2.41753C1.05039 2.58753 0.673 2.8754 0.407446 3.24476C0.141892 3.61411 0.000102042 4.04837 0 4.49262L0 16.3836C0.00191866 17.8726 0.639066 19.3001 1.77168 20.353C2.9043 21.4059 4.43991 21.9982 6.04167 22H22.9583C24.5601 21.9982 26.0957 21.4059 27.2283 20.353C28.3609 19.3001 28.9981 17.8726 29 16.3836V4.49262C29.0001 4.04833 28.8585 3.61398 28.5931 3.2445C28.3277 2.87501 27.9504 2.58697 27.5089 2.41681Z" fill="url(#paint0_linear_2862_1277)"/>
        <defs>
          <linearGradient id="paint0_linear_2862_1277" x1="0.725" y1="4.02734" x2="25.4906" y2="23.268" gradientUnits="userSpaceOnUse">
            <stop stopColor="#BCA36B"/>
            <stop offset="0.469606" stopColor="#E8D4A7"/>
            <stop offset="1" stopColor="#BCA36B"/>
          </linearGradient>
        </defs>
      </svg>
    </i>
  )
}

