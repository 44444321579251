import styles from '@/styles/components/common/CallToAction.module.scss'
import { Button } from '@/components/controls/Button'
import { PlanetDecor } from '@/components/decors/PlanetDecor'
import parse from 'html-react-parser'
import { useQuery } from '@/utils/url'
import { Wing } from '@/components/icons/Wing'
import { Info } from '@/components/icons/Info'
import { CrossBtn } from '@/components/controls/CrossBtn'
import { Headline2 } from '@/components/common/Headline2'
import { useState } from 'react'
import { emitter } from '@/utils/emitter'
import Modal from 'react-modal'

interface Props {
  title: string
}

export const CallToAction2: React.FC<Props> = ({ title }) => {
  const checkoutLink = useQuery('/checkout')
  const [show, setShow] = useState(false)

  const handleOpen = () => {
    emitter.emit('guarantee-info-opened')
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  return (
    <div className={styles.cta}>
      <div className={styles.cta__container + ' container'}>
        <h3 className={`${styles.cta__title} ${styles.cta__title_center}`}>{parse(title)}</h3>
        <div className='w-full md:max-w-[380px]'>
          <Button href={checkoutLink} boldFont={true} type="primary" noWrap={true} textCenter={true} fullWidth={true} disableArrow={true} maxWidth={380}><Wing className='mr-[15px]'/>Sign Up &amp; Pay Now</Button>
          <p className='text-center mt-[10px] font--primary text-[17px] flex items-center justify-center cursor-pointer' onClick={handleOpen}><em className='mr-[4px]'>No Contract. 30-Day Guarantee</em><Info/></p>
        </div>
      </div>
      <PlanetDecor className="bottom-0 h-[320px] sm2:h-[500px] sm2:bottom-[-220px] xl:h-[1000px] xl:bottom-[-440px]"/>
      <Modal isOpen={show} className='modal-content--white' ariaHideApp={false}>
        <CrossBtn className="modal__close-btn modal__close-btn-sec opacity-20" color="black" onClick={() => {
          handleClose()
        }}/>
        <div className='color--black mt-[20px]'>
          <Headline2 tag="h1" className="text-[22px] mb-[20px]">Try Our Service Risk Free</Headline2>
          <p className='mb-[20px]'>We’re confident that our service gets results, and we want you to give it a try.</p>
          <p className='mb-[20px]'>But if it doesn’t work for you, you shouldn’t pay. That’s why we offer a simple guarantee:</p>
          <p className='font-bold italic'>If you don’t feel like this is working for you and producing amazing results, then email us within the first 30 days and we’ll promptly refund your payment in full (no hard feelings)!</p>
        </div>
      </Modal>
    </div>
  )
}
