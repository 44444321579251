import { Headline2 } from '@/components/common/Headline2'
import { Button } from '../controls/Button'
import { Info } from '../icons/Info'
import { Shield } from '../icons/Shield'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/reducers/index'
import { useQuery } from '@/utils/url'
import { PlansElite } from '../forms/PlansElite'
import { Modal } from '@/components/common/Modal'
import { useState } from 'react'
import { useRouter } from 'next/router'
import { emitter } from '@/utils/emitter'

type Props = {
  prices: any,
}

export function PricingEliteSection({ prices }: Props) {
  const checkoutState = useSelector((state: RootState) => state.checkout)
  const router = useRouter()
  const [show, setShow] = useState(false)
  const [error, setError] = useState(false)

  const handleClose = () => {
    setShow(false)
  }

  const handleOpen = () => {
    setShow(true)
  }

  const submitHandler = () => {
    setError(false)
    if (!checkoutState.plan) {
      setError(true)
    } else {
      emitter.emit('homepage-plans-section-submitted', {
        plan: checkoutState.plan,
        monthlyPrice: checkoutState.prices?.monthly,
        annualPrice: checkoutState.prices?.annual,
        monthlyElitePrice: checkoutState.prices?.eliteMonthly,
        annualElitePrice: checkoutState.prices?.eliteAnnual
      })

      router.replace({
        pathname: '/checkout',
        query: router.query
      })
    }
  }

  return (
    <section className="pt-[60px] lg:pt-[140px] pb-70-to-130 bg--dark" id="pricing">
      <div className="container container--sec container--max-w-980 md:!p-0">
        <Headline2 tag="h2" className="text-36-to-48 mb-40-to-50 text-center">Pricing</Headline2>
        <div className='flex flex-col md:flex-row max-w-[520px] mx-auto mb-[20px]'>
          <p className='text-18-to-20 text-center'>Action orientated 1-to-1 coaching for entrepreneurs, creatives, and executives, at a fraction of the price of business coaching.</p>
        </div>
        <PlansElite prices={prices} />
        <div className='flex justify-center mt-[50px] flex-col items-center'>
          <div className='relative'>
            <Button className={`${error ? 'anim-shaking' : ''}`} boldFont={true} type="primary" noWrap={true} fullWidth={true} maxWidth={380} onClick={submitHandler}>Continue To Checkout</Button>
            {error && <p className='error font--accent absolute text-[12px] text-center w-full color-red'>You need to select a plan</p>}
          </div>
          <div className='max-w-[285px] text-[14px] font--primary flex mt-[26px]'>
            <Shield style={{fill: '#5ad082', marginLeft: '6px', marginRight: '10px'}}/>
            <div>
              <p className=''><em className='mr-[4px] font-bold'>30-Day Satisfaction Guarantee.</em></p>
              <p className='font-thin cursor-pointer' onClick={handleOpen}><em>No Contract. Cancel anytime. Prorated refund on all annual plan unused services. <Info style={{display: 'inline-block', marginBottom: '-2px'}}/></em></p>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} multiopen={true} onClose={handleClose}>
        <div className='color--black'>
          <Headline2 tag="h1" className="text-[22px] mb-[20px]">Try Our Service Risk Free</Headline2>
          <p className='mb-[20px]'>We’re confident that our service gets results, and we want you to give it a try.</p>
          <p className='mb-[20px]'>But if it doesn’t work for you, you shouldn’t pay. That’s why we offer a simple guarantee:</p>
          <p className='font-bold italic'>If you don’t feel like this is working for you and producing amazing results, then email us within the first 30 days and we’ll promptly refund your payment in full (no hard feelings)!</p>
        </div>
      </Modal>
    </section>
  )
}
