import Head from 'next/head'
import { CallToAction2 } from '@/components/variants/components/CallToAction2'
import { WhySection4 } from '@/components/variants/components/WhySection4'

import home_page_img from '@/public/images/site-preview.gif'
import { emitter } from '@/utils/emitter'
import { useEffect, useState } from 'react'
import { HeroHomeVariant5 } from '@/components/variants/components/HeroHomeVariant5'
import { OurCoachesSection } from '@/components/sections/OurCoachesSection'
import { FAQSectionVariant } from '@/components/variants/components/FAQSectionVariant'
import { TestimonialsVariantSection } from '@/components/variants/components/TestimonialsVariantSection'
import { TestimonialsVariantSection2 } from '@/components/variants/components/TestimonialsVariantSection2'
import { HowItWorksSection3 } from '@/components/variants/components/HowItWorksSection3'
import { BonusesSection } from '@/components/sections/BonusesSection'
import { ServiceRiskFreeComponent3 } from '@/components/variants/components/ServiceRiskFreeSection3'
import dynamic from 'next/dynamic'
import { PricingEliteSection } from '@/components/sections/PricingEliteSection'

const structuredData = {
  '@context': 'https://schema.org',
  'type': 'EducationalOrganization',
  'name': 'Commit Action',
  'url': 'https://www.commitaction.com/',
  'logo': '',
  'sameAs': [
    'https://www.facebook.com/commitaction/',
    'https://www.linkedin.com/company/commit-action',
    'https://www.instagram.com/commitaction/',
    'https://twitter.com/CommitAction'
  ]
}

type Props = {
  prices: any,
}

const DynamicHardScienceOffcanvas = dynamic( // @ts-ignore
  () => import('@/components/offcanvas/HardScienceOffcanvas').then((mod) => mod.HardScienceOffcanvas),
  { ssr: false }
)

export const IndexPageVariant3: React.FC<Props> = ({ prices }) => {
  const [show, setShow] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const closeHandler = () => {
    setShow(false)

    emitter.emit('hard-science-offcanvas-closed')
  }

  const showHandler = (e: any) => {
    if (e && e.stopPropagation) {
      e.stopPropagation()
    }

    setLoaded(true)
    setShow(true)

    emitter.emit('hard-science-offcanvas-opened')
  }

  useEffect(() => {
    if (location.hash === '#hard-science-open') {
      showHandler({})
    }
    emitter.on('hard-science-offcanvas-open', showHandler)

    return () => {
      emitter.off('hard-science-offcanvas-open', showHandler)
    }
  }, [])

  return (
    <>
      <Head>
        <title>Guaranteed to keep you accountable | Commit Action</title>
        <meta name="description" content="Get the power of structured accountability & strategic planning with Commit Action. Join today to achieve more & turn your big ideas into tangible results." />
        <link rel="canonical" href={process.env.NEXT_PUBLIC_SITE} />
        <meta property="og:url" content={`${process.env.NEXT_PUBLIC_SITE}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Guaranteed to keep you accountable | Commit Action" />
        <meta property="og:description" content="Get the power of structured accountability & strategic planning with Commit Action. Join today to achieve more & turn your big ideas into tangible results." />
        <meta property="og:image" content={`${process.env.NEXT_PUBLIC_SITE}${home_page_img.src}`} />
        <meta name="twitter:title" content="Guaranteed to keep you accountable | Commit Action" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Get the power of structured accountability & strategic planning with Commit Action. Join today to achieve more & turn your big ideas into tangible results."/>
        <meta name="twitter:image" content={`${process.env.NEXT_PUBLIC_SITE}${home_page_img.src}`} />
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }} />
      </Head>
      <HeroHomeVariant5/>
      <div className="bg--primary">
        <WhySection4 />
        <TestimonialsVariantSection/>
        <HowItWorksSection3/>
        <OurCoachesSection/>
        <TestimonialsVariantSection2/>
        <PricingEliteSection prices={prices}/>
        <BonusesSection></BonusesSection>
        <ServiceRiskFreeComponent3></ServiceRiskFreeComponent3>
        <FAQSectionVariant></FAQSectionVariant>
        <DynamicHardScienceOffcanvas show={show} close={closeHandler} />
        <CallToAction2 title="Join now and achieve <br/> your goals faster" />
      </div>
    </>
  )
}
